//
//  Tree
//

.tree {
  // Css variables
  --#{$prefix}tree-icon-size: #{$tree-icon-size};
  --#{$prefix}tree-icon-gap: #{$tree-icon-gap};
  --#{$prefix}tree-icon-color-open: var(--#{$prefix}success);
  --#{$prefix}tree-icon-color-default: var(--#{$prefix}gray-500);
  --#{$prefix}tree-icon-color-close: var(--#{$prefix}gray-500);
  --#{$prefix}tree-line-color: var(--#{$prefix}gray-200);

  .tree-node {
    padding-left: $tree-node-padding-x;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .tree-sub {
    padding: $tree-node-padding-y 0;
  }

  .tree-content {
    display: flex;
    align-items: center;
    padding: $tree-node-padding-y 0;
  }

  .tree-wrapper {
    padding-left: calc(
      var(--#{$prefix}tree-icon-size) + var(--#{$prefix}tree-icon-size)
    );
  }

  .tree-section {
    display: flex;
    align-items: baseline;
    padding-left: var(--#{$prefix}tree-icon-gap);
  }

  .tree-toggle {
    display: flex;
    align-items: center;
    width: var(--#{$prefix}tree-icon-size);

    .tree-icon-default {
      font-size: 1.5rem;
      color: var(--#{$prefix}tree-icon-color-default);
    }

    .tree-icon-open {
      font-size: 1.5rem;
      color: var(--#{$prefix}tree-icon-color-open);
    }

    .tree-icon-close {
      font-size: 1.5rem;
      color: var(--#{$prefix}tree-icon-color-close);
    }

    &.collapsed {
      .tree-icon-close {
        display: flex;
      }

      .tree-icon-open {
        display: none;
      }
    }

    &:not(.collapsed) {
      .tree-icon-close {
        display: none;
      }

      .tree-icon-open {
        display: flex;
      }
    }
  }

  & > .tree-node {
    padding-left: 0 !important;
  }

  &.tree-line {
    .tree-sub {
      border-left: 1px solid var(--#{$prefix}tree-line-color);
      margin-left: calc(var(--#{$prefix}tree-icon-size) / 2);
    }
  }
}
